.lumanti-mel-catering-menu-container {
    display: flex;
    flex-direction: column;
    background: rgb(23,24,25);
    padding: 45px 0;
    align-items: center;
}

.lumanti-mel-catering-menu-heading {
    text-align: center;
}

.lumanti-mel-catering-menu-options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 15px;
}

.lumanti-mel-catering-menu-option-heading {
    text-align: center;
    color: rgb(228,197,144)
}

.lumanti-mel-catering-menu-price {
    color: rgb(228,197,144)
}

.lumanti-mel-catering-menu-separater {
    display: block;
    margin: 10px auto;
    text-align: center;
    width: 100px;
}

.lumanti-mel-catering-menu-option {
    width: 250px;
    background: rgb(23,24,25);
    border: 1px solid rgb(228,197,144);
    border-radius: 24px;
    padding: 16px;
}

.lumanti-mel-catering-menu-additional {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lumanti-mel-catering-menu-disclouser {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lumanti-mel-catering-menu-disclouser-text {
    text-align: center;
    color: rgb(228,197,144);
}

@media (max-width: 600px) {
    .lumanti-mel-catering-menu-heading {
        margin: 16px 15px;
    }

    .lumanti-mel-catering-menu-option {
        width: 100%;
        margin: 0 15px;
    }

    .lumanti-mel-catering-menu-disclouser {
        margin: 50px 15px 0;
    }
}